
import { defineComponent, ref, onBeforeUnmount, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import InputAmount from '@/components/InputAmount.vue'
import { useCarWash } from '@/composables/useCarWash'
import { ICarWashStockItem } from '@/interfaces/ICarWash'
import { ITempComment } from '@/interfaces/IDelivery'
import localforage from 'localforage'

export default defineComponent({
    components: {
        InputAmount,
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const {
            isLoading,
            activeCarWash,
            activeCarWashStock,
            fetchCarWashStock,
            updateCarWashStock,
            resetActiveCarWashStock,
            storeActiveCarWashStock,
            getStoredActiveCarWashStock,
        } = useCarWash()
        const isEditActive = ref(false)
        const tempComment = ref<ITempComment>()
        const carWashStockProducts = ref<ICarWashStockItem[]>([])
        const initialCarWashStockProducts = ref<ICarWashStockItem[]>([])
        const higherQuantityThanInitialList = ref<ICarWashStockItem[]>([])
        const changeEditStatus = async (status: boolean) => {
            isEditActive.value = status
            if (!isEditActive.value) {
                activeCarWashStock.value.products = JSON.parse(
                    JSON.stringify(initialCarWashStockProducts.value),
                )
                carWashStockProducts.value = activeCarWashStock.value.products
                localforage.removeItem('tempComment')
                tempComment.value = undefined
                higherQuantityThanInitialList.value = []
                localforage.removeItem('activeCarWashStockProducts')
            }
        }

        const addProductAmount = (product: ICarWashStockItem, quantity: number) => {
            if (
                !activeCarWashStock.value.products.find(
                    (elem: ICarWashStockItem) => elem.id === product.id,
                )
            ) {
                activeCarWashStock.value.products.push(product)
            } else {
                // if object is in the array - change it's quantity
                const objectToChange = activeCarWashStock.value.products.find(
                    elem => elem.id === product.id,
                )
                if (objectToChange) {
                    objectToChange.quantity = quantity
                }
            }
            detectIfQuantitIsBiggerThanInitial(product)
        }

        const detectIfQuantitIsBiggerThanInitial = async (productToCheck: ICarWashStockItem) => {
            const initialProduct = initialCarWashStockProducts.value.find(
                initialProduct => initialProduct.id === productToCheck.id,
            )
            if (
                initialProduct &&
                !higherQuantityThanInitialList.value.find(elem => elem.id === initialProduct.id)
            ) {
                if (productToCheck.quantity > initialProduct.quantity) {
                    higherQuantityThanInitialList.value.push(productToCheck)
                }
            } else {
                const objectToChange = higherQuantityThanInitialList.value.find(
                    product => product.id === productToCheck.id,
                )
                if (
                    initialProduct &&
                    objectToChange &&
                    objectToChange.quantity <= initialProduct.quantity
                ) {
                    const objectToChangeIndex = higherQuantityThanInitialList.value.findIndex(
                        elem => elem.id === objectToChange.id,
                    )
                    higherQuantityThanInitialList.value.splice(objectToChangeIndex, 1)
                }
            }

            await storeActiveCarWashStock()
        }

        const addComment = async () => {
            await storeActiveCarWashStock()

            router.push({
                name: 'AddCommentManager',
                params: { id: route.params.id, name: route.name as string },
            })
        }
        onMounted(async () => {
            await fetchCarWashStock(+route.params.id)
            const savedStock = (await getStoredActiveCarWashStock()) as ICarWashStockItem[]
            tempComment.value = (await localforage.getItem('tempComment')) as ITempComment

            // Save inital stock without reactivity for reset
            initialCarWashStockProducts.value = JSON.parse(
                JSON.stringify(activeCarWashStock.value.products),
            )
            // set saved stock as current stock
            if (savedStock) {
                activeCarWashStock.value.products = savedStock
                carWashStockProducts.value = savedStock
            } else if (activeCarWashStock.value) {
                carWashStockProducts.value = activeCarWashStock.value.products
            }
            // check for saved product quantities and block or unblock submit button
            carWashStockProducts.value.forEach(product =>
                detectIfQuantitIsBiggerThanInitial(product),
            )
            if (higherQuantityThanInitialList.value.length) {
                changeEditStatus(true)
            }
            localforage.removeItem('activeCarWashStockProducts')
        })
        const submitChange = async () => {
            await updateCarWashStock()
            router.push({ name: 'CarWashDetails', params: { id: activeCarWash.value?.id } })
        }

        onBeforeUnmount(() => {
            resetActiveCarWashStock()
        })

        return {
            route,
            isLoading,
            isEditActive,
            activeCarWashStock,
            carWashStockProducts,
            submitChange,
            changeEditStatus,
            addProductAmount,
            router,
            tempComment,
            higherQuantityThanInitialList,
            addComment,
            detectIfQuantitIsBiggerThanInitial,
        }
    },
})
